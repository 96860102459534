<template>
    <div class="video-grid-collection" v-if="data && data.length > 0">
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="
                !overviewItemType ||
                (overviewItemType && overviewItemType !== 'classes')
            ">
                <VideoOverviewItem v-for="item in data" :key="item.mediaId" :data="item" />
            </div>
        </transition>
    </div>
</template>

<script>
// @import component
import VideoOverviewItem from "@/components/VideoOverviewItem";

export default {
    name: "VideoGridCollection",
    components: {
        VideoOverviewItem,
    },
    inject: ["isAuthenticated"],
    data() {
        return {};
    },
    props: ["data", "overviewItemType"],
    created() {

    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
